import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import MouseFollower from "mouse-follower";
import gsap from "gsap";

const root = ReactDOM.createRoot(document.getElementById("root"));

MouseFollower.registerGSAP(gsap);

const cursor = new MouseFollower({
	speed: 1.5,
	skewing: 1,
	skewingMedia: 0.5,
});

function Image(props) {
	// Define an array of image names
	const images = ["library.jpg", "ladyjustice.jpg", "pen.jpg"];

	// Select a random image from the array
	const randomImage = images[Math.floor(Math.random() * images.length)];

	const imagePath = process.env.PUBLIC_URL + "/" + randomImage;

	return (
		<img
			src={imagePath}
			data-cursor-img={imagePath}
			alt={randomImage}
			{...props}
		/>
	);
}

function Site() {
	const [isActive, setIsActive] = useState(false);

	useEffect(() => {
		const timer = setTimeout(() => {
			setIsActive(true);
		}, 50);

		// Cleanup function to clear the timer if the component unmounts before the timer finishes
		return () => clearTimeout(timer);
	}, []);

	// Wait 1000ms after page loads
	setTimeout(() => {
		// Select all the spans
		const spans = document.querySelectorAll("span");

		// Loop over the spans
		for (let i = 0; i < spans.length; i++) {
			// Use setTimeout to add the class with a delay
			setTimeout(() => {
				spans[i].classList.add("active");
			}, i * 100); // The delay is increased for each span
		}
	}, 1000);

	return (
		<div className="coming-soon">
			<Image className={isActive ? "image active" : "image"} />

			<div className={isActive ? "content active" : "content"}>
				<div className="name">
					<p>
						<span>S</span>
						<span>a</span>
						<span>n</span>
						<span>j</span>
						<span>a</span>
					</p>
					<p>
						<span>P</span>
						<span>e</span>
						<span>r</span>
						<span>i</span>
						<span>ć</span>
					</p>
				</div>
				<ul className="information" data-cursor="-hidden">
					<li>
						<a href="tel:381611178264">061 / 11 78 264</a>
					</li>
					<li>
						<a href="mailto:sanja@peric.law">sanja@peric.law</a>
					</li>
					<li>
						<a href="https://maps.app.goo.gl/vPdioHqJdpc6ygtPA">
							Maksima Gorkog 9/2/7, Novi Sad
						</a>
					</li>
				</ul>
			</div>
		</div>
	);
}

root.render(
	<React.StrictMode>
		<Site />
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
